import Box from '@mui/material/Box';
import React from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { useTheme } from '@mui/material';
import { useColorMode } from './../context/ColorMode';

type LayoutProps = {
  children: JSX.Element;
};

function LayoutSwagger(props: LayoutProps) {
  const { children } = props;
  const theme = useTheme();
  const { toggleColorMode } = useColorMode();
  return (
    <Box
      display="flex"
      height="100%"
      width="100vw"
      flexDirection={'column'}
      alignItems="flex-start"
    >
      <ResponsiveAppBar
        title={' - SWAGGER'}
        theme={theme}
        toggleColorMode={toggleColorMode}
      />
      <Box display="flex" flex={'0 1 100%'}>
        <Box
          display="flex"
          flexDirection={'row'}
          flexWrap={'nowrap'}
          width="100vw"
        >
          <Box flex="1 1 40%" minWidth={'20vw'} maxWidth={'10vw'}></Box>
          <Box flex="4 1 60%" maxWidth={'60vw'}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LayoutSwagger;
