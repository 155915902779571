import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo-iad.png';
import { CtxProps } from '../context/ColorMode';
import { SwaggerType } from '../type/Swagger';
import { getMenuList } from '../utils/dirReader';
type ResponsiveAppBarProps = {
  title: string;
  theme: Theme;
  toggleColorMode: CtxProps['toggleColorMode'];
};
const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function ResponsiveAppBar(props: ResponsiveAppBarProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<SwaggerType[]>([]);
  const { title, toggleColorMode } = props;
  const [open, setOpen] = React.useState(false);
  const logoIadEngage = './assets/img/logo-product.png';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFileList(getMenuList());
  }, []);

  return (
    <>
      <Box
        flex={'0 1 auto'}
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        width="100%"
        p={3}
        gap={3}
      >
        <Box justifyContent="center" flexGrow={3}>
          <AppBar position="static">
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Box justifyContent="flex-start">
                  <img src={logo} alt="logo" style={{ width: '80px' }} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}></Box>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'flex' } }}
                  color="#0077b7"
                >
                  <Box justifyContent="flex-start">
                    <Button variant="text" onClick={() => navigate('/')}>
                      <img
                        src={logoIadEngage}
                        alt="logo"
                        style={{ width: '120px' }}
                      />
                    </Button>
                  </Box>
                  {title}
                </Typography>

                <IconButton
                  sx={{ ml: 1 }}
                  onClick={toggleColorMode}
                  color="inherit"
                >
                  {theme.palette.mode === 'dark' ? (
                    <Brightness7Icon />
                  ) : (
                    <Brightness4Icon />
                  )}
                </IconButton>
              </Toolbar>
            </Container>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {fileList.map((weblinkItem, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() =>
                    navigate('/public/api-docs', {
                      state: { fileName: weblinkItem.fileName },
                    })
                  }
                >
                  <ListItemText primary={weblinkItem.menuItem} />
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
          </Main>
        </Box>
      </Box>
    </>
  );
}
export default ResponsiveAppBar;
