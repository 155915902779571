import AuthenticationProvider, {
  AutoLogin,
  LogginIn,
} from '@iad-os/react-ghost-auth';
import axios from 'axios';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import authConfig from './authConfig';
import LayoutSwagger from './layout/LayoutSwagger';
import ProtectedRoutes from './Routes/ProtectedRoutes';
import PublicRoutes from './Routes/PublicRoutes';

function App() {
  return (
    <>
      <AuthenticationProvider config={authConfig} axios={axios}>
        <LayoutSwagger>
          <Routes>
            <Route path="/protected/*" element={<ProtectedRoutes />} />
            <Route path="/public/*" element={<PublicRoutes />} />
            <Route path="/" element={<Navigate to="/public" />} />
            <Route path="/*" element={<h2>⚠ OOPS... Page not found! ⚠</h2>} />
          </Routes>
        </LayoutSwagger>
        <LogginIn>
          <h2>🔄 Loading...</h2>
        </LogginIn>
        <AutoLogin />
      </AuthenticationProvider>
    </>
  );
}

export default App;
