import React from 'react';
import { useLocation } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

function SwaggerViewer() {
  const { state }: { state: any } = useLocation();

  return (
    <div>
      <SwaggerUI url={`/assets/data/${state.fileName}`} />
    </div>
  );
}

export default SwaggerViewer;
