import { Public } from '@iad-os/react-ghost-auth';
import React from 'react';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import SwaggerViewer from '../components/SwaggerViewer';

function PublicRouters() {
  return (
    <Public>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <h1>Swagger home page</h1>
              {/* <h2>Public page 🌐</h2>
              <Link to={'/protected'}>Go to Protected page</Link> */}
            </>
          }
        />
        <Route path="/api-docs" element={<SwaggerViewer />} />
      </Routes>
    </Public>
  );
}

export default PublicRouters;
