import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export type CtxProps = { toggleColorMode: () => void };

const ColorModeContext = React.createContext({} as CtxProps);

export function useColorMode() {
  return useContext(ColorModeContext);
}

const SWAGGER_THEME_MODE = 'ghost_theme_mode';

function ColorModeProvider(props: { children: React.ReactNode }) {
  const [mode, setMode] = useState<Theme['palette']['mode']>(
    (localStorage.getItem(SWAGGER_THEME_MODE) as Theme['palette']['mode']) ||
      'light'
  );
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useEffect(() => {
    mode && localStorage.setItem(SWAGGER_THEME_MODE, mode);
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputBase: {
            styleOverrides: {
              input: {
                fontFamily: 'W-Imprint',
              },
            },
          },
        },
        palette: {
          mode,
          primary: {
            main: '#9ccc65',
            light: '#b8d49e',
            dark: '#597537',
          },
          secondary: {
            main: '#ff7043',
          },
          info: {
            main: '#9e9e9e',
          },
          success: {
            main: '##00bcd4',
          },
          tertiary:
            mode === 'light'
              ? {
                  main: '#000',
                }
              : { main: '#FFF' },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default ColorModeProvider;
